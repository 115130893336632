<template>
    <el-popover placement="bottom" width="200" trigger="manual" v-model="visible">
        <div style="text-align: center;">
            <div>
                <h1>商品表更新</h1>
            </div>
            <div>
                <el-progress type="circle" :percentage="Number(Progress.toFixed(2))" :status="sta"></el-progress>
            </div>
            <div>
                <el-upload class="upload-demo" action="https://lcbh.top:7788/upload" :on-preview="handlePreview"
                    :on-remove="handleRemove" :on-success="handleSuccess">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
        </div>
        <el-button style="margin-top: 3px;" type="primary" slot="reference"
            @click="visible = !visible">上传表格更新商品信息</el-button>
    </el-popover>
</template>

<script>
import Qs from 'qs';
export default {
    props: {
        type: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            Progress: 0,
            sta: null,
            visible: false,
            TableBox: [],
            atype: [],
            btype: [],
            ctype: [],
            cigarette: [],
        };
    },
    methods: {
        async getAtype(page) {
            let type = 'atype'
            let res = await this.$api.getAtype({ page, type })
            this.atype = res.result
        },
        async getBtype(page) {
            let type = 'btype'
            let res = await this.$api.getAtype({ page, type })
            this.btype = res.result
        },
        async getCtype(page) {
            let type = 'ctype'
            let res = await this.$api.getAtype({ page, type })
            this.ctype = res.result
        },
        async getCigarette(page) {
            let type = 'cigarette'
            let res = await this.$api.getAtype({ page, type })
            this.cigarette = res.result
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },
        async handleSuccess(response, file, fileList) {
            // console.log('成功', response, file.name, fileList);
            let res = await this.$api.getExcel({ filename: file.name, sheetname: '总' })
            this.TableBox = res.data
            // console.log('1', this.TableBox);
            var num = 0
            var j =0
            if(this.type=='atype'){
                for (var i = 0;i <= this.atype.length; i++) {
                    if (j>this.TableBox.length-1) {
                        this.sta = 'success'
                        this.$message({
                            message:'更新成功,一共更新了'+num+'条数据',
                            type: 'success'
                        });
                        break;
                    } else if(i>this.atype.length-1){
                        const params = Qs.stringify({ barcode: this.TableBox[j].商品条码, table: this.type, CommodityName: this.TableBox[j].商品名称, category: this.TableBox[j].分类 })
                        let result = await this.$api.updataProductInfo(params)
                        num++
                        i=0
                        j++
                        this.Progress = this.Progress+100/this.TableBox.length
                    }else if(this.atype[i].tiaoma == this.TableBox[j].商品条码){
                        j++
                        i=0
                        this.Progress = this.Progress+100/this.TableBox.length
                    }
                }
            }else if(this.type=='btype'){
                for (var i = 0;i <= this.btype.length; i++) {
                    if (j>this.TableBox.length-1) {
                        this.sta = 'success'
                        this.$message({
                            message:'更新成功,一共更新了'+num+'条数据',
                            type: 'success'
                        });
                        break;
                    } else if(i>this.btype.length-1){
                        const params = Qs.stringify({ barcode: this.TableBox[j].商品条码, table: this.type, CommodityName: this.TableBox[j].商品名称, category: this.TableBox[j].分类 })
                        let result = await this.$api.updataProductInfo(params)
                        num++
                        i=0
                        j++
                        this.Progress = this.Progress+100/this.TableBox.length
                    }else if(this.btype[i].tiaoma == this.TableBox[j].商品条码){
                        j++
                        i=0
                        this.Progress = this.Progress+100/this.TableBox.length
                    }
                }
            }else if(this.type=='ctype'){
                for (var i = 0;i <= this.ctype.length; i++) {
                    if (j>this.TableBox.length-1) {
                        this.sta = 'success'
                        this.$message({
                            message:'更新成功,一共更新了'+num+'条数据',
                            type: 'success'
                        });
                        break;
                    }else if(i>this.ctype.length-1){
                        const params = Qs.stringify({ barcode: this.TableBox[j].商品条码, table: this.type, CommodityName: this.TableBox[j].商品名称, category: this.TableBox[j].分类 })
                        let result = await this.$api.updataProductInfo(params)
                        num++
                        i=0
                        j++
                        this.Progress = this.Progress+100/this.TableBox.length
                    }else if(this.ctype[i].tiaoma == this.TableBox[j].商品条码){
                        j++
                        i=0
                        this.Progress = this.Progress+100/this.TableBox.length
                    }
                }
            }else if(this.type=='cigarette'){
                for (var i = 0;i <= this.cigarette.length; i++) {
                    if (j>this.TableBox.length-1) {
                        this.sta = 'success'
                        this.$message({
                            message:'更新成功,一共更新了'+num+'条数据',
                            type: 'success'
                        });
                        break;
                    } else if(i>this.cigarette.length-1){
                        const params = Qs.stringify({ barcode: this.TableBox[j].商品条码, table: this.type, CommodityName: this.TableBox[j].商品名称, category: this.TableBox[j].分类 })
                        let result = await this.$api.updataProductInfo(params)
                        num++
                        i=0
                        j++
                        this.Progress = this.Progress+100/this.TableBox.length
                    }else if(this.cigarette[i].tiaoma == this.TableBox[j].商品条码){
                        j++
                        i=0
                        this.Progress = this.Progress+100/this.TableBox.length
                    }
                }
            }
        }
    },
    created() {
        this.getAtype(1)
        this.getBtype(1)
        this.getCtype(1)
        this.getCigarette(1)
    }
}
</script>

<style></style>