export default {
    namespaced:true,
    state:{
        token: {},
    },
    mutations:{
        getAdmin(state,payload){
            state.token = payload;
            sessionStorage.setItem('token', JSON.stringify(payload))
        },
        removeAdmin(state){
            state.token = null;
            sessionStorage.removeItem('token');
        },
    }
}