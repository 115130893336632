<template>
    <div>
        <div class="pageheader">
            <el-page-header @back="goBack" content="修改商品">
            </el-page-header>
        </div>
        <!-- 表格 -->
        <div class="form">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="110px" class="demo-ruleForm">
                <el-form-item label="商品条码：" prop="tiaoma">
                    <el-input v-model="ruleForm.tiaoma" style="width: 500px;" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="商品名称：" prop="shangpinmingcheng">
                    <el-input v-model="ruleForm.shangpinmingcheng"></el-input>
                </el-form-item>
                <div style="display: flex;">
                    <el-form-item label="商品售价：" prop="lingshoujia">
                        <el-input v-model="ruleForm.lingshoujia" type="number" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="商品进货价：" prop="jinhuojia">
                        <el-input v-model="ruleForm.jinhuojia" type="number" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="商品种类：" prop="type">
                        <el-select v-model="ruleForm.type" placeholder="请选择" style="width: 100px;" disabled>
                            <el-option label="a类" value="atype"></el-option>
                            <el-option label="b类" value="btype"></el-option>
                            <el-option label="c类" value="ctype"></el-option>
                            <el-option label="烟类" value="cigarette"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品分类：" prop="fenlei">
                        <el-input v-model="ruleForm.fenlei" style="width: 100px;"></el-input>
                    </el-form-item>
                    <el-form-item label="商品二分类：" prop="erfenlei">
                        <el-input v-model="ruleForm.erfenlei" style="width: 100px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="商品预警：" prop="yujing">
                        <el-input v-model="ruleForm.yujing" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="淘汰标识：" prop="taotaibiaoshi">
                        <el-input v-model="ruleForm.taotaibiaoshi" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="花城库存：" prop="huacheng">
                        <el-input v-model="ruleForm.huacheng" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="花城日补：" prop="huachengribu">
                        <el-input v-model="ruleForm.huachengribu" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="新都汇库存" prop="xinduhui">
                        <el-input v-model="ruleForm.xinduhui" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="新都汇日补" prop="xinduhuiribu">
                        <el-input v-model="ruleForm.xinduhuiribu" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="名扬库存：" prop="mingyang">
                        <el-input v-model="ruleForm.mingyangribu" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="名扬日补：" prop="mingyangribu">
                        <el-input v-model="ruleForm.mingyangribu" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="店库存" prop="diankucun">
                        <el-input v-model="ruleForm.diankucun" style="width: 200px;" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="最高值" prop="zuigaozhi">
                        <el-input v-model="ruleForm.zuigaozhi" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="岭东库存：" prop="lingdong">
                        <el-input v-model="ruleForm.lingdong" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="岭东日补：" prop="lingdongribu">
                        <el-input v-model="ruleForm.lingdongribu" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="成隆库存：" prop="chenglong">
                        <el-input v-model="ruleForm.chenglong" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="成隆日补：" prop="chenglongribu">
                        <el-input v-model="ruleForm.chenglongribu" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="供应商：" prop="gonghuoshang">
                        <el-input v-model="ruleForm.gonghuoshang" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
                    <el-button type="danger" @click="delProduct">删除</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
    
<script>
import Qs from 'qs';
export default {
    data() {
        return {
            ruleForm: {
                tiaoma: '',
                shangpinmingcheng: '',
                lingshoujia: '',
                fenlei: '',
                erfenlei: '',
                yujing: '',
                huacheng: '',
                huachengribu: '',
                xinduhui:'',
                xinduhuiribu: '',
                mingyang:'',
                mingyangribu: '',
                lingdong:'',
                lingdongribu: '',
                chenglong:'',
                chenglongribu: '',
                diankucun:0,
                zuigaozhi: '',
                taotaibiaoshi:'',
                gonghuoshang:'',
                jinhuojia: '',
            },
            rules: {
                tiaoma: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
                type: [{ required: true, message: '请选择商品种类', trigger: 'change' }]
            },
        }
    },
    methods: {
        //修改
        async submitForm() {
            const params = Qs.stringify({
                barcode: this.ruleForm.tiaoma,
                CommodityName: this.ruleForm.shangpinmingcheng,
                price: this.ruleForm.lingshoujia,
                PurchasePrice: this.ruleForm.jinhuojia,
                category: this.ruleForm.fenlei,
                SecCategory: this.ruleForm.erfenlei,
                alerts: this.ruleForm.yujing,
                status: this.ruleForm.taotaibiaoshi,
                huacheng: this.ruleForm.huacheng,
                huaribu: this.ruleForm.huachengribu,
                xinduhui: this.ruleForm.xinduhui,
                xinribu: this.ruleForm.xinduhuiribu,
                mingyang: this.ruleForm.mingyang,
                mingribu: this.ruleForm.mingyangribu,
                lingdong: this.ruleForm.lingdong,
                lingribu: this.ruleForm.lingdongribu,
                chenglong: this.ruleForm.chenglong,
                chengribu: this.ruleForm.chenglongribu,
                diankucun: Number(this.ruleForm.huacheng)+Number(this.ruleForm.xinduhui)+Number(this.ruleForm.mingyang),
                HighestValue: this.ruleForm.zuigaozhi,
                supplier: this.ruleForm.gonghuoshang,
                type:this.$route.query.type
            })
            console.log('params',params);
            let res = await this.$api.postEditProduct(params)
            if (res.status == 200) {
                this.$message({
                    message: '修改成功',
                    type: 'success'
                });
                this.$router.back();
            } else {
                this.$message.error('添加失败');
            }
        },
        //删除
        delProduct() {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let id = this.ruleForm.tiaoma
                this.postDelProduct(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async postDelProduct(id) {
            var Type = this.$route.query.type
            const params = Qs.stringify({ type: Type, id: id })
            let res = await this.$api.postDelProduct(params)
            if (res.status == 200) {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.$router.back();
            } else {
                this.$message.error('删除失败');
            }
        },
        //返回上一个页面
        goBack() {
            this.$router.back();
        },
        //获取商品信息
        async getProductInfo() {
            var Type = this.$route.query.type
            var id = this.$route.query.id
            let res = await this.$api.getProductInfo({ id,Type })
            this.ruleForm = res.data[0]
            this.ruleForm.type = this.$route.query.type
        },
    },
    created() {
        this.getProductInfo()
    }
}
</script>
    
<style lang="less" scoped>
.pageheader {
    padding: 10px 10px;
}

//表单css
.form {
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 rgba(153, 187, 255, .33);
    padding: 20px 20px 20px 0;
    width: 50%;
    margin: 20px auto;

    p {
        color: #535353;
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        width: 95%;
    }

    .demo-ruleForm {
        width: 95%;
    }
}
</style>