<template>
  <div>
    <div class="pageheader">
      <el-page-header @back="goBack" content="添加商品">
      </el-page-header>
    </div>
    <!-- 表格 -->
    <div class="form">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="110px" class="demo-ruleForm">
        <el-form-item label="商品条码：" prop="barcode">
          <el-input v-model="ruleForm.barcode" style="width: 500px;"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：" prop="CommodityName">
          <el-input v-model="ruleForm.CommodityName"></el-input>
        </el-form-item>
        <div style="display: flex;">
          <el-form-item label="商品售价：" prop="price">
            <el-input v-model="ruleForm.price" type="number" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="商品进货价：" prop="PurchasePrice">
            <el-input v-model="ruleForm.PurchasePrice" type="number" style="width: 200px;"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="商品种类：" type="region" prop="region">
            <el-select v-model="ruleForm.region" placeholder="请选择">
              <el-option label="a类" value="atype"></el-option>
              <el-option label="b类" value="btype"></el-option>
              <el-option label="c类" value="ctype"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="淘汰标识：" prop="status">
            <el-input v-model="ruleForm.status" style="width: 200px;"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="商品分类：" prop="category">
            <el-input v-model="ruleForm.category" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="商品二分类：" prop="SecCategory">
            <el-input v-model="ruleForm.SecCategory" style="width: 200px;"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="商品预警：" prop="alerts">
            <el-input v-model="ruleForm.alerts" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="供应商：" prop="supplier">
            <el-input v-model="ruleForm.supplier" style="width: 200px;"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="花城日补：" prop="huaribu">
            <el-input v-model="ruleForm.huaribu" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="新都日补：" prop="xinribu">
            <el-input v-model="ruleForm.xinribu" style="width: 200px;"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="名扬日补：" prop="mingribu">
            <el-input v-model="ruleForm.mingribu" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="岭东日补：" prop="lingribu">
            <el-input v-model="ruleForm.lingribu" style="width: 200px;"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex;">
          <el-form-item label="成隆日补：" prop="chengribu">
            <el-input v-model="ruleForm.chengribu" style="width: 200px;"></el-input>
          </el-form-item>
          <el-form-item label="最高值：" prop="HighestValue">
            <el-input v-model="ruleForm.HighestValue" style="width: 200px;"></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
  
<script>
import Qs from 'qs';
export default {
  data() {
    return {
      ruleForm: {
        region: '',
        barcode: '',
        CommodityName: '',
        price: '',
        PurchasePrice: '',
        category: '',
        SecCategory: '',
        alerts: '',
        huaribu: '',
        xinribu: '',
        mingribu: '',
        lingribu: '',
        chengribu: '',
        supplier: '',
        HighestValue:'',
      },
      rules: {
        barcode: [{ required: true, message: '请输入商品条码', trigger: 'blur' }],
        CommodityName: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        region: [{ required: true, message: '请选择商品种类', trigger: 'change' }]
      }
    }
  },
  methods: {
    async submitForm() {
      const params = Qs.stringify({
        region: this.ruleForm.region,
        barcode: this.ruleForm.barcode,
        CommodityName: this.ruleForm.CommodityName,
        price: this.ruleForm.price,
        PurchasePrice: this.ruleForm.PurchasePrice,
        category: this.ruleForm.category,
        SecCategory: this.ruleForm.SecCategory,
        alerts: this.ruleForm.alerts,
        status:this.ruleForm.status,
        huaribu: this.ruleForm.huaribu,
        xinribu: this.ruleForm.xinribu,
        mingribu: this.ruleForm.mingribu,
        lingribu: this.ruleForm.lingribu,
        supplier: this.ruleForm.supplier,
        HighestValue:this.ruleForm.HighestValue
      })
      let res = await this.$api.postProductInfo(params)
      if(res.status==200){
        this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.$router.back();
      }else{
        this.$message.error('添加失败');
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>
  
<style lang="less" scoped>
.pageheader {
  padding: 10px 10px;
}

//表单css
.form {
  border-radius: 20px;
  box-shadow: 0 2px 20px 0 rgba(153, 187, 255, .33);
  padding: 20px 20px 20px 0;
  width: 50%;
  margin: 20px auto;

  p {
    color: #535353;
    font-size: 20px;
    font-weight: 700;
    line-height: 60px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    width: 95%;
  }

  .demo-ruleForm {
    width: 95%;
  }
}</style>