<template>
  <div>
    <!--顶部内容-->
    <Header></Header>
    <!--内柔-->
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './Header.vue';
export default {
  components:{
    Header,
  }
}
</script>

<style>
</style>