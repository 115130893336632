//公共请求
import base from "./base"
import axios from './request'
const api = {
    getAdmin(params){
        return axios.post(base.getAdmin,params)
    },
    getExcel(params){
        return axios.get(base.getExcel,{params})
    },
    getAColumn(params){
        return axios.get(base.getAColumn,{params})
    },
    getAtype(params){
        return axios.get(base.getAtype,{params})
    },
    getBarcodeinfo(params){
        return axios.get(base.getBarcodeinfo,{params})
    },
    getProductInfo(params){
        return axios.get(base.getProductInfo,{params})
    },
    postProductInfo(params){
        return axios.post(base.postProductInfo,params)
    },
    postDelProduct(params){
        return axios.post(base.postDelProduct,params)
    },
    postEditProduct(params){
        return axios.post(base.postEditProduct,params)
    },
    postAddColumn(params){
        return axios.post(base.postAddColumn,params)
    },
    postEditColumn(params){
        return axios.post(base.postEditColumn,params)
    },
    postDelColumn(params){
        return axios.post(base.postDelColumn,params)
    },
    postUploadInventory(params){
        return axios.post(base.postUploadInventory,params)
    },
    getShop(params){
        return axios.get(base.getShop,{params})
    },
    postShop(params){
        return axios.post(base.postShop,params)
    },
    delShop(params){
        return axios.post(base.delShop,params)
    },
    postAddCigarette(params){
        return axios.post(base.postAddCigarette,params)
    },
    postEditCigarette(params){
        return axios.post(base.postEditCigarette,params)
    },
    getPickup(params){
        return axios.get(base.getPickup,{params})
    },
    getPermissions_Review(params){
        return axios.get(base.getPermissions_Review,{params})
    },
    getPermissions(params){
        return axios.get(base.getPermissions,{params})
    },
    agreePermissions(params){
        return axios.post(base.agreePermissions,params)
    },
    getAllPickup(params){
        return axios.get(base.getAllPickup,{params})
    },
    updataProductInfo(params){
        return axios.post(base.updataProductInfo,params)
    },
    delUserInfo(params){
        return axios.post(base.delUserInfo,params)
    },
    changeUserStaff(params){
        return axios.post(base.changeUserStaff,params)
    },
    getShopList(params){
        return axios.get(base.getShopList,{params})
    },
    getFranchisee(params){
        return axios.get(base.getFranchisee,{params})
    },
    getResponsible(params){
        return axios.get(base.getResponsible,{params})
    },
    getBranchShop(params){
        return axios.get(base.getBranchShop,params)
    },
    setBranchShop(params){
        return axios.post(base.setBranchShop,params)
    },
    removeBranchShop(params){
        return axios.post(base.removeBranchShop,params)
    },
    postAddUser(params){
        return axios.post(base.postAddUser,params)
    }
}

export default api