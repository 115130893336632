<template>
  <div> 
    <el-table :data="tableData" style="width: 100%;" :cell-style="cs">
      <el-table-column prop="tiaoma" label="条码" width="140" class="tiaomao" fixed></el-table-column>
      <el-table-column prop="shangpinmingcheng" label="商品名称" width="300" fixed></el-table-column>
      <el-table-column v-for="(item, i) in columnData" :key="item + i" :prop="columnData[i].title"
          :label="columnData[i].name" width="100"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <Pagination :total="total" :pageSize="pageSize" @CurrentChange="CurrentChange"></Pagination>
  </div>
</template>
<script>
import Pagination from '@/components/pagination/Pagination.vue';
export default {
  components: { Pagination },
  data() {
    return {
      tableData: [],
      columnData: [],//标题列表
      total: 10,
      pageSize: 1,
    }
  },
  methods: {
    async getAColumn() {
      let Column = 'bColumn'
      let res = await this.$api.getAColumn({Column})
      this.columnData = res.data
    },
    async getAllPickup(page) {
      var type = 'btype'
      let res = await this.$api.getAllPickup({ type,page })
      if (res.status == 200) {
        this.tableData = res.data
        this.total = res.total
        this.pageSize = res.pageSize
      }
    },
    //分页
    CurrentChange(val) {
      this.getAllPickup(val)
    },
    cs({ row, column, rowIndex, columnIndex }) {
      let cellStyle = 'background-color: rgb(248, 203, 173);';
      if (columnIndex === 6) {
        if (Number(row.yujing) >= Number(row.huacheng)) {
          return cellStyle
        }
      }
      if (columnIndex === 8) {
        if (Number(row.yujing) >= Number(row.xinduhui)) {
          return cellStyle
        }
      }
      if (columnIndex === 10) {
        if (Number(row.yujing) >= Number(row.mingyang)) {
          return cellStyle
        }
      }
    }
  },
  created() {
    this.getAColumn()
    this.getAllPickup(1)
  }
}
</script>

<style></style>