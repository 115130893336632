import { render, staticRenderFns } from "./Productinfo.vue?vue&type=template&id=91c84750&scoped=true&"
import script from "./Productinfo.vue?vue&type=script&lang=js&"
export * from "./Productinfo.vue?vue&type=script&lang=js&"
import style0 from "./Productinfo.vue?vue&type=style&index=0&id=91c84750&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91c84750",
  null
  
)

export default component.exports