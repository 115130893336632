<template>
  <div style="text-align: center;">
    <h1 style="color:red;">欢迎使用联成管理系统</h1>
    <el-image :src="imgs" style="width: 500px;height: 500px;"></el-image>
  </div>
</template>

<script>
export default {
  data() {
    return {
    	imgs:require("@/assets/images/logo.png")
    }
  }  
}
</script>

<style>

</style>