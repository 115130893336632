<template>
    <div>
        <div class="pageheader">
            <el-page-header @back="goBack" content="添加商品">
            </el-page-header>
        </div>
        <div class="form">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
                <el-form-item label="商品条码：" prop="tiaoma">
                    <el-input v-model="ruleForm.tiaoma" style="width: 500px;" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="商品名称：" prop="shangpinmingcheng">
                    <el-input v-model="ruleForm.shangpinmingcheng"></el-input>
                </el-form-item>
                <div style="display: flex;">
                    <el-form-item label="商品售价：" prop="lingshoujia">
                        <el-input v-model="ruleForm.lingshoujia" type="number" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="商品进货价：" prop="jinhuojia">
                        <el-input v-model="ruleForm.jinhuojia" type="number" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="淘汰标识：" prop="taotaibiaoshi">
                        <el-input v-model="ruleForm.taotaibiaoshi" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="商品预警：" prop="yujing">
                        <el-input v-model="ruleForm.yujing" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="花城日补：" prop="huachengribu">
                        <el-input v-model="ruleForm.huachengribu" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="新都汇日补：" prop="xinduhuiribu">
                        <el-input v-model="ruleForm.xinduhuiribu" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="名扬日补：" prop="mingyangribu">
                        <el-input v-model="ruleForm.mingyangribu" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="岭东日补：" prop="lingdongribu">
                        <el-input v-model="ruleForm.lingdongribu" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex;">
                    <el-form-item label="最低库存：" prop="zuidikucun">
                        <el-input v-model="ruleForm.zuidikucun" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="最高值：" prop="zuigaozhi">
                        <el-input v-model="ruleForm.zuigaozhi" style="width: 200px;"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="产品图：" prop="chanpintu">
                    <el-upload ref="upload" action="http://lcbh.top:7788/uploadpic" list-type="picture-card" :limit="1"
                        :auto-upload="false" :file-list="filelist" :on-success="handleSuccess" :on-change="handleChange">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">{{ button }}</el-button>
                    <el-button @click="resetForm('ruleForm')" :disabled="disabled">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import Qs from 'qs';
export default {
    data() {
        return {
            ruleForm: {
                tiaoma: '',
                shangpinmingcheng: '',
                lingshoujia: '',
                jinhuojia: '',
                taotaibiaoshi: '',
                yujing: '',
                huachengribu: '',
                xinduhuiribu: '',
                mingyangribu: '',
                lingdongribu: '',
                zuidikucun: '',
                zuigaozhi: '',
                chanpintu: [],
            },
            rules: {
                tiaoma: [{ required: true, message: '请输入商品条码', trigger: 'blur' }],
                shangpinmingcheng: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
            },
            filelist: [],
            disabled:false,
            button:'添加'
        };
    },
    methods: {
        async submitForm() {
            var id = this.$route.query.id
            this.$refs.upload.submit();
            const params = Qs.stringify({
                barcode: this.ruleForm.tiaoma,
                CommodityName: this.ruleForm.shangpinmingcheng,
                price: this.ruleForm.lingshoujia,
                PurchasePrice: this.ruleForm.jinhuojia,
                alerts: this.ruleForm.yujing,
                status: this.ruleForm.taotaibiaoshi,
                huaribu: this.ruleForm.huachengribu,
                xinribu: this.ruleForm.xinduhuiribu,
                mingribu: this.ruleForm.mingyangribu,
                lingribu: this.ruleForm.lingdongribu,
                LowestValue: this.ruleForm.zuidikucun,
                HighestValue: this.ruleForm.zuigaozhi,
                images: this.ruleForm.chanpintu
            })
            if(id!=null){
                let res = await this.$api.postEditCigarette(params)
                if (res.status == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.$router.back();
                } else {
                    this.$message.error('修改失败');
                }
            }else{
                let res = await this.$api.postAddCigarette(params)
            if (res.status == 200) {
                this.$message({
                    message: '添加成功',
                    type: 'success'
                });
                this.$router.back();
            } else {
                this.$message.error('添加失败');
            }
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        goBack() {
            this.$router.back();
        },
        handleSuccess(response, file, fileList) {
            // console.log(response, file, fileList);
            // this.ruleForm.产品图 = 'localhost:7788/'+response.url.slice(10)
            // console.log(this.ruleForm.产品图);
        },
        handleChange(file, fileList) {
            this.ruleForm.chanpintu = 'http://lcbh.top:7788/' + file.name
        },
        async getProductInfo() {
            var Type = this.$route.query.type
            var id = this.$route.query.id
            if(id!=null){
                let res = await this.$api.getProductInfo({ id,Type })
                this.ruleForm = res.data[0]
                this.disabled = true
                this.button = '修改'
            }
        },
    },
    created() {
        this.getProductInfo()
    }
}
</script>

<style lang="less" scoped>
.pageheader {
    padding: 10px 10px;
}

//表单css
.form {
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 rgba(153, 187, 255, .33);
    padding: 20px 20px 20px 0;
    width: 50%;
    margin: 20px auto;

    p {
        color: #535353;
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        width: 95%;
    }

    .demo-ruleForm {
        width: 95%;
    }
}
</style>