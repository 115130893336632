import axios from 'axios'
const instance = axios.create({
	baseURL:process.env.NODE_ENV=='production'?process.env.VUE_APP_BASE_API:'',
	// baseURL:process.env.VUE_APP_INTERFACE_URL,
	timeout: 300000,//请求设置超时时间 单位毫秒 5s超时 接口请求5s没有响应数据 停止请求
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
})
 
instance.interceptors.request.use((config) => {
	return config
}, err => {
	return Promise.reject(err)
})
 
instance.interceptors.response.use((res) => {
	console.log('接受的数据')
	return res.data
}, err => {
	return Promise.reject(err)
})
 
export default instance
