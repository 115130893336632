<template>
  <div class="header">
    <el-row>
      <el-col :span="2" :offset="22">
        <div class="quit">
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  methods: {
   
  }
}
</script>

<style lang="less" scoped>
.header{
    height: 56px;
    background: #1e78bf;
}
.quit{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}
</style>