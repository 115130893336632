<template>
  <div style="text-align: center;">
    <div><h1>{{this.ShopName}}店库存更新</h1></div>
    <div>
      <el-progress type="circle" :percentage="Number(Progress.toFixed(2))" :status="sta"></el-progress>
    </div>
    <div>
      <el-upload class="upload-demo" action="https://lcbh.top:7788/upload" :on-preview="handlePreview"
        :on-remove="handleRemove" :on-success="handleSuccess">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
import Qs from 'qs';
import pinyin from 'js-pinyin';
export default {
  props:{
    ShopName:{
      type:String,
      default:'',
    },
    type:{
      type:String,
      default:'',
    },
  },
  data() {
    return {
      atype: [],
      btype: [],
      ctype: [],
      cigarette: [],
      TableBox: [],
      Progress: 0,
      sta: null,
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async getAtype(page) {
      let type = 'atype'
      let res = await this.$api.getAtype({ page, type })
      this.atype = res.result
    },
    async getBtype(page) {
      let type = 'btype'
      let res = await this.$api.getAtype({ page, type })
      this.btype = res.result
    },
    async getCtype(page) {
      let type = 'ctype'
      let res = await this.$api.getAtype({ page, type })
      this.ctype = res.result
    },
    async getCigarette(page) {
      let type = 'cigarette'
      let res = await this.$api.getAtype({ page, type })
      this.cigarette = res.result
    },
    async handleSuccess(response, file, fileList) {
      pinyin.setOptions({checkPolyphone: false, charCase: 1});
      let ShopName = pinyin.getFullChars(this.ShopName)
      let res = await this.$api.getExcel({filename:file.name,sheetname:'数据导出'})
      this.TableBox = res.data
      var num = 100 /  (Number(this.atype.length)+Number(this.btype.length)+Number(this.ctype.length)+Number(this.cigarette.length))
      for (var i = 0; i < this.atype.length; i++) {
        var j = 0
        if (this.atype[i].tiaoma == this.TableBox[j].商品条形码) {
          const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'atype',shop:ShopName })
          let result = await this.$api.postUploadInventory(params)
          if (result.status == 200) {
            j = 0
            this.Progress = Number(this.Progress) + Number(num)
          }
        } else if (j > this.TableBox.length) {
          j = 0
          i = i + 1
          this.Progress = Number(this.Progress) + Number(num)
        } else {
          for (; j <= this.TableBox.length; j++) {
            if (j >= this.TableBox.length) {
              j = 0
              this.Progress = Number(this.Progress) + Number(num)
              break
            } else if (this.atype[i].tiaoma == this.TableBox[j].商品条形码) {
              const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'atype',shop:ShopName })
              let result = await this.$api.postUploadInventory(params)
              if (result.status == 200) {
                j = 0
                this.Progress = Number(this.Progress) + Number(num)
                break
              }
            }
          }
        }
      }for (var i = 0; i < this.btype.length; i++) {
        var j = 0
        if (this.btype[i].tiaoma == this.TableBox[j].商品条形码) {
          const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'btype',shop:ShopName })
          let result = await this.$api.postUploadInventory(params)
          if (result.status == 200) {
            j = 0
            this.Progress = Number(this.Progress) + Number(num)
          }
        } else if (j > this.TableBox.length) {
          j = 0
          i = i + 1
          this.Progress = Number(this.Progress) + Number(num)
        } else {
          for (; j <= this.TableBox.length; j++) {
            if (j >= this.TableBox.length) {
              j = 0
              this.Progress = Number(this.Progress) + Number(num)
              break
            } else if (this.btype[i].tiaoma == this.TableBox[j].商品条形码) {
              const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'btype',shop:ShopName })
              let result = await this.$api.postUploadInventory(params)
              if (result.status == 200) {
                j = 0
                this.Progress = Number(this.Progress) + Number(num)
                break
              }
            }
          }
        }
      }for (var i = 0; i < this.ctype.length; i++) {
        var j = 0
        if (this.ctype[i].tiaoma == this.TableBox[j].商品条形码) {
          const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'ctype',shop:ShopName })
          let result = await this.$api.postUploadInventory(params)
          if (result.status == 200) {
            j = 0
            this.Progress = Number(this.Progress) + Number(num)
          }
        } else if (j > this.TableBox.length) {
          j = 0
          i = i + 1
          this.Progress = Number(this.Progress) + Number(num)
        } else {
          for (; j <= this.TableBox.length; j++) {
            if (j >= this.TableBox.length) {
              j = 0
              this.Progress = Number(this.Progress) + Number(num)
              break
            } else if (this.ctype[i].tiaoma == this.TableBox[j].商品条形码) {
              const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'ctype',shop:ShopName })
              let result = await this.$api.postUploadInventory(params)
              if (result.status == 200) {
                j = 0
                this.Progress = Number(this.Progress) + Number(num)
                break
              }
            }
          }
        }
      }for (var i = 0; i < this.cigarette.length; i++) {
        var j = 0
        if (this.cigarette[i].tiaoma == this.TableBox[j].商品条形码) {
          const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'cigarette',shop:ShopName })
          let result = await this.$api.postUploadInventory(params)
          if (result.status == 200) {
            j = 0
            this.Progress = Number(this.Progress) + Number(num)
          }
        } else if (j > this.TableBox.length) {
          j = 0
          i = i + 1
          this.Progress = Number(this.Progress) + Number(num)
        } else {
          for (; j <= this.TableBox.length; j++) {
            if (j >= this.TableBox.length) {
              j = 0
              this.Progress = Number(this.Progress) + Number(num)
              break
            } else if (this.cigarette[i].tiaoma == this.TableBox[j].商品条形码) {
              const params = Qs.stringify({ barcode: this.TableBox[j].商品条形码, inventory: this.TableBox[j].库存, table: 'cigarette',shop:ShopName })
              let result = await this.$api.postUploadInventory(params)
              if (result.status == 200) {
                j = 0
                this.Progress = Number(this.Progress) + Number(num)
                break
              }
            }
          }
        }
      }
      if (this.Progress.toFixed(0) >= 100) {
        this.sta = 'success'
        this.$message({
          message: this.ShopName+'库存更新成功',
          type: 'success'
        });
      } else {
        this.sta = 'exception'
        this.$message.error(this.ShopName+'库存更新失败');
      }
    },
  },
  created() {
    this.getAtype(1)
    this.getBtype(1)
    this.getCtype(1)
    this.getCigarette(1)
  }
}
</script>

<style></style>
