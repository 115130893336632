<template>
  <div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#112f50"
      text-color="#fff"
      active-text-color="#ffd04b" router>
      <el-menu-item>
        <span slot="title">联成管理系统</span>
      </el-menu-item>
      <el-menu-item index="/">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="/product">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>商品管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/Atype">a类商品信息</el-menu-item>
          <el-menu-item index="/Btype">b类商品信息</el-menu-item>
          <el-menu-item index="/Ctype">c类商品信息</el-menu-item>
          <el-menu-item index="/Cigarette">烟类商品信息</el-menu-item>
          <el-menu-item index="/inventory">库存管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/Pickup">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>每日分捡</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/a">a类</el-menu-item>
          <el-menu-item index="/b">b类</el-menu-item>
          <el-menu-item index="/c">c类</el-menu-item>
          <el-menu-item index="/cig">烟</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="/Staff">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>员工</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/StaffManagement">用户管理</el-menu-item>
          <el-menu-item index="/AddUser">添加用户</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.el-menu{
  border-right: 0;
/deep/ .is-active{
    background: #1e78bf !important;
    color: #fff !important;
  }
}
</style>