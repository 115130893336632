<template>
  <div>
    <div style="margin-top: 10px;">
      <el-button type="primary" @click="dialogFormVisible = true" style="margin-left: 10px;">添加店铺</el-button>
      <el-dialog title="添加分店" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="分店名称" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="分店类型" :label-width="formLabelWidth">
            <el-select v-model="form.region" placeholder="请选择分店类型">
              <el-option label="门店" value=1></el-option>
              <el-option label="烟行" value=2></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addShop">确 定</el-button>
        </div>
      </el-dialog>
      <el-popover placement="right" width="200" trigger="click">
        <el-table :data="shop">
          <el-table-column width="100" property="ShopName" label="门店"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="delShop(scope.row)"  size="mini" type="danger" >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button slot="reference" type="danger" style="margin-left: 10px;">删除店铺</el-button>
      </el-popover>
    </div>
    <div style="display: flex;">
      <Upload v-for="(item, i) in shop" :key="item + i" :ShopName="shop[i].ShopName" :type="shop[i].type" class="box">
      </Upload>
    </div>
  </div>
</template>

<script>
import Qs from 'qs';
import Upload from '@/components/upload/Upload.vue';
export default {
  data() {
    return {
      shop: [],
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
      },
      formLabelWidth: '120px',
      visible: false,
    }
  },
  components: {
    Upload,
  },
  methods: {
    async getShop() {
      let res = await this.$api.getShop()
      this.shop = res.data
    },
    async addShop() {
      let ShopName = this.form.name
      let typeid = this.form.region
      let table = ''
      if(typeid==1){
        table='门店' 
      }else if(typeid==2){
        table='烟行'
      }
      // console.log(table);
      const params = Qs.stringify({ ShopName: ShopName, table: table,typeid:typeid })
      let res = await this.$api.postShop(params)
      if (res.status == 200) {
        this.$message({
          message: '添加成功',
          type: 'success'
        });
      }else{
        this.$message.error('添加失败');
      }
      this.dialogFormVisible = false
      this.getShop()
    },
    async delShop(data) {
      const id = Qs.stringify({id:data.id})
      let res = await this.$api.delShop(id)
      if(res.status==200){
        this.$message({
          message: '删除成功成功',
          type: 'success'
        });
        this.getShop()
      }else{
        this.$message.error('删除失败');
      }
    }
  },
  created() {
    this.getShop()
  }
}
</script>

<style lang="less" scoped>
.box {
  margin-right: 20px;
}
</style>