<template>
  <div>
    <div style="display: flex;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="商品条码">
          <el-input v-model="formInline.code" placeholder="请输入商品条码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-top: 3px;margin-right: 10px;">
        <el-popover placement="top" width="160" v-model="visible">
          <el-input v-model="name" placeholder="请输入列名"></el-input>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="addColumn">确定</el-button>
          </div>
          <el-button slot="reference" type="primary">添加列</el-button>
        </el-popover>
      </div>
      <div style="margin-top: 3px;margin-right: 10px;">
        <el-popover placement="right" width="400" trigger="click">
          <el-table :data="columnData" height="250">
            <el-table-column prop="name" label="列名" width="150"></el-table-column>
            <el-table-column label="操作" fixed="right" width="150">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="editColumn(scope.row)">编辑</el-button>
                <el-button size="mini" type="danger" @click="delColumn(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button slot="reference" type="primary ">编辑列</el-button>
        </el-popover>
      </div>
      <div style="margin-top: 3px;margin-right: 10px;"><el-button type="primary" @click="addproduct">添加商品信息</el-button></div>
      <Updata :type="'ctype'"></Updata>
    </div>
    <el-page-header class="pageheader" @back="goBack" content="查询结果" :class="{success:!isSuccess,error:isSuccess}"></el-page-header>
    <div>
      <el-table :data="tableData" style="width: 100%;" border :cell-style="cellStyle">
        <el-table-column prop="tiaoma" label="条码" width="140" class="tiaomao" fixed></el-table-column>
        <el-table-column prop="shangpinmingcheng" label="商品名称" width="350" fixed>
          <template slot-scope="scope">
            <el-link @click="productinfo(scope.row)" style="cursor: pointer;">{{ scope.row.shangpinmingcheng }}</el-link>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, i) in columnData" :key="item + i" :prop="columnData[i].title"
          :label="columnData[i].name" width="100"></el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="productinfo(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination :total="total" :pageSize="pageSize" @CurrentChange="CurrentChange" :class="{success:isSuccess,error:!isSuccess}"></Pagination>
  </div>
</template>

<script>
import Qs from 'qs';
import Pagination from '@/components/pagination/Pagination.vue';
import Updata from '@/components/updata/updata.vue';
import pinyin from 'js-pinyin';
export default {
  components: { Pagination,Updata },
  data() {
    return {
      formInline: {
        code: ''
      },
      columnData: [],//标题列表
      tableData: [],//商品列表
      total: 10,
      pageSize: 1,
      page: 1,
      name: '',
      visible: false,
      isSuccess:false,
    }
  },
  methods: {
    goBack() {
        this.getAtype(this.page)
      },
    //搜索
    async onSubmit() {
      let code = this.formInline.code
      if(code==''){
        this.$message({
          message: '搜索栏不能为空',
          type: 'warning'
        });
      }else{
        let Type = 'ctype'
        let res = await this.$api.getBarcodeinfo({ code,Type })
        if (res.status == 200) {
          this.tableData = res.data
          this.isSuccess = true
        } else {
          this.$message.error('查询失败');
        }
      }
    },
    //分页
    CurrentChange(val) {
      this.page = val
      this.getAtype(val)
    },
    //获取b类商品列表的header
    async getAColumn() {
      let Column = 'cColumn'
      let res = await this.$api.getAColumn({Column})
      this.columnData = res.data
    },
    //a类商品列表
    async getAtype(page) {
      let type = 'ctype'
      let res = await this.$api.getAtype({ page,type })
      this.tableData = res.data
      this.total = res.total
      this.pageSize = res.pageSize
      this.isSuccess = false
    },
    //跳转添加商品页面
    addproduct() {
      this.$router.push({ path: '/Addinformation' });
    },
    //跳转商品详情页面
    productinfo(data) {
      let type = 'ctype'
      this.$router.push({
        path: "/Productinfo",
        query: { id: data.tiaoma, page: this.page,type:type }
      });
    },
    //改变表格某一列或者某一个单元格文本颜色 
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // 定义样式变量
      let cellStyle = 'background-color: rgb(90, 90, 90);text-align: center;color:white;';
      let tablebody = 'text-align: center;'
      let cellStyle1 = 'background-color: rgb(230, 230, 230);text-align: center;color:black;'
      let cellStyle2 = 'background-color: rgb(230, 230, 230);text-align: center;color:black;font-weight: bold;'
      //return cellStyle  // 返回最终处理过的样式 这样写就是让全部行被style修饰
      // 返回最终处理过的样式 只让账号状态这个属性的属性被style修饰
      if (column.label == '条码') {
        return cellStyle
      }
      if (columnIndex % 2 == 0 && columnIndex != 4) {
        return cellStyle1
      }
      if (column.label == '二分类') {
        return cellStyle2
      }
      return tablebody
    },
    //删除
    handleDelete(data) {
      this.$confirm('此操作将永久删除该条码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postDelProduct(data.条码)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async postDelProduct(id) {
      let type = 'ctype'
      const params = Qs.stringify({ type: type, id: id })
      let res = await this.$api.postDelProduct(params)
      if (res.status == 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.getAtype(this.page)
      } else {
        this.$message.error('删除失败');
      }
    },
    //添加列
    async addColumn() {
      let name = this.name
      let type = 'ctype'
      let Column = 'cColumn'
      pinyin.setOptions({checkPolyphone: false, charCase: 1});
      let title = pinyin.getFullChars(name)
      const params = Qs.stringify({ name: name,type: type,title: title,Column: Column })
      let res = await this.$api.postAddColumn(params)
      if (res.status == 200) {
        this.$message({
          message: '添加成功',
          type: 'success'
        });
        this.visible = false
        this.getAColumn()
      } else {
        this.$message.error('添加失败');
      }
    },
    //编辑列名
    editColumn(data){
      this.$prompt('请输入列名', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          pinyin.setOptions({checkPolyphone: false, charCase: 1});
          let newtitle = pinyin.getFullChars(value)
          let Column = 'cColumn'
          let type = 'ctype'
          const params = Qs.stringify({ value:value,id:data.id,type:type,Column:Column,newtitle:newtitle,oldtitle:data.title })
          this.postEditColumn(params)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });
    },
    async postEditColumn(data){
      let res = await this.$api.postEditColumn(data)
      if(res.status==200){
        this.$message({
          message: '编辑成功',
          type: 'success'
        });
        this.getAColumn()
      }else{
        this.$message.error('编辑失败');
      }
    },
    //删除列
    delColumn(data){
      let type = 'ctype'
      let Column = 'cColumn'
      const params = Qs.stringify({ id:data.id,type:type,Column:Column,title:data.title })
      this.$confirm('此操作将永久删除该列, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postDelColumn(params)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async postDelColumn(data){
      let res = await this.$api.postDelColumn(data)
      if(res.status==200){
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.getAColumn()
      }else{
        this.$message.error('删除失败');
      }
    },
  },
  created() {
    this.getAColumn()
    this.getAtype(1)
  }
}
</script>

<style lang="less" scoped>
.pageheader{
  margin-bottom: 22px;
}
&.success{
  display: none;
}
.demo-form-inline {
  margin-top: 3px;
  margin-left: 10px;
}
</style>