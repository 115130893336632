<template>
  <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="username" label="用户名" width="180"></el-table-column>
    <el-table-column prop="shop" label="门店" width="180"></el-table-column>
    <el-table-column prop="positions" label="职位" width="180"></el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button size="mini" type="primary" @click="handleEdit1(scope.$index, scope.row)" style="margin-right: 5px;">编辑门店</el-button>
        <el-dialog title="编辑门店" :visible.sync="dialogTableVisible1">
          <el-select v-model="scope.row.id" placeholder="请选择" @change="handchange1">
            <el-option v-for="item in options" :key="item.id" :label="item.ShopName" :value="item.id">
            </el-option>
          </el-select>
          <el-button size="mini" type="primary" style="margin: 10px;" @click="changeShop(scope.row)">确认</el-button>
        </el-dialog>
        <el-button size="mini" type="primary" @click="handleEdit2(scope.$index, scope.row)" style="margin-right: 5px;">编辑职位</el-button>
        <el-dialog title="编辑职位" :visible.sync="dialogTableVisible2">
          <el-select v-model="value2" placeholder="请选择" @change="handchange2">
            <el-option v-for="item in staff" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-button size="mini" type="primary" style="margin: 10px;" @click="changeUserStaff(scope.row)">确认</el-button>
        </el-dialog>
        <el-button size="mini" type="primary" @click="handleEdit3(scope.$index, scope.row)" style="margin-right: 5px;">编辑负责门店</el-button>
        <el-dialog title="编辑负责门店" :visible.sync="dialogTableVisible3">
          <!-- <div v-for="city in cities" :key="city">
            <el-radio v-model="radio" label="1">{{city}}</el-radio>
          </div> -->
          <el-transfer v-model="checkboxGroup1" :data="cities" :titles="['可选门店','已负责门店']" @change="changeshop"></el-transfer>
        </el-dialog>
        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import Qs from 'qs';
import pinyin from 'js-pinyin';
export default {
  data() {
    return {
      cities: [],
      checkboxGroup1:[],
      tableData: [],
      dialogTableVisible1: false,
      dialogTableVisible2: false,
      dialogTableVisible3: false,
      options: [],
      value1:'',
      value2:'',
      username:null,
      staff:[{
        label:'店员',
        value:1
      },
      {
        label:'店长',
        value:2
      },
      {
        label:'分拣员',
        value:3
      }],
      str:null,
    }
  },
  methods: {
    handleEdit1(index, row) {
      this.dialogTableVisible1 = true
    },
    handleEdit2(index, row) {
      this.dialogTableVisible2 = true
    },
    handleEdit3(index, row){
      this.dialogTableVisible3 = true
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该员工信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteUserInfo(row.username)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async deleteUserInfo(data) {
      var params = Qs.stringify({ username: data })
      let res = await this.$api.delUserInfo(params)
      if (res.status == 200) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getPermissions()
      }
    },
    async getPermissions() {
      let res = await this.$api.getPermissions()
      this.username = res.data[0].username
      if (res.status == 200) {
        this.tableData = res.data
      }
    },
    async getshop() {
      let res = await this.$api.getShop()
      if (res.status == 200) {
        this.options = res.data
      }
    },
    handchange2(val){
      this.str = this.staff.find(i => i.value === val)?.label
    },
    async changeUserStaff(row){
      var pass = 1
      if(this.str=='分拣员'){
        pass =2
      }
      const params = Qs.stringify({ username:row.username,str:this.str,column:'positions',pass:pass,shopid:row.shopid })
      let res = await this.$api.changeUserStaff(params)
      if(res.status==200){
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.dialogTableVisible2 = false
        this.getPermissions()
      }else{
        this.$message.error('修改失败');
      }
    },
    handchange1(val){
      this.str = this.options.find(i => i.id === val)?.ShopName
    },
    async changeShop(row){
      pinyin.setOptions({checkPolyphone: false, charCase: 1});
      var shopid = pinyin.getFullChars(this.str)
      const params = Qs.stringify({ username:row.username,str:this.str,column:'shop',pass:row.pass,shopid:shopid })
      let res = await this.$api.changeUserStaff(params)
      if(res.status==200){
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.dialogTableVisible1 = false
        this.getPermissions()
      }else{
        this.$message.error('修改失败');
      }
    },
    async getResponsible(username){
      let res = await this.$api.getResponsible({username})
      var arr = res.data
      const arrdata = arr.map((i,n) => 
        i.id
      )
      this.checkboxGroup1 = arrdata
    },
    async getBranchShop(){
      let res = await this.$api.getBranchShop()
      var arr = res.data
      const arrdata = arr.map((i,n) => ({
        label:i.ShopName, 
        key:i.id
      }))
      this.cities = arrdata
    },
    changeshop(value, direction, movedKeys){
      var num = 0
      if(direction=='left'){
        console.log('移除');
        for(var i=0;i<movedKeys.length;i++){
          this.removeBranchShop(movedKeys[i])
        }
      }else if(direction=='right'){
        console.log('添加');
        for(var i=0;i<movedKeys.length;i++){
          this.setBranchShop(movedKeys[i])
        }
      }else{
        this.$message.error('出错了，请稍后再试!');
      }
    },
    async removeBranchShop(e){
      var params = Qs.stringify({ shopid: e })
      let res = await this.$api.removeBranchShop(params)
      console.log('res',res);
      if(res.status==200){
        this.$message({
          message: '操作成功',
          type: 'success'
        });
      }else{
        this.$message.error('操作失败');
      }
    },
    async setBranchShop(e){
      var params = Qs.stringify({ shopid: e,username:this.username })
      let res = await this.$api.setBranchShop(params)
      if(res.status==200){
        this.$message({
          message: '操作成功',
          type: 'success'
        });
      }else{
        this.$message.error('操作失败');
      }
    }
  },
  created() {
    this.getPermissions()
    this.getshop()
    this.getBranchShop()
  }
}
</script>

<style></style>