//接口地址
const base={
    getAdmin:'/api/admin',//后台管理登录
    getExcel:'/api/getExcel',
    getAColumn:'/api/getAColumn',
    getAtype:'/api/getAtype',
    getBarcodeinfo:'/api/getBarcodeinfo',
    getProductInfo:'/api/getProductInfo',
    postProductInfo:'/api/postProductInfo',
    postDelProduct:'/api/postDelProduct',
    postEditProduct:'/api/postEditProduct',
    postAddColumn:'/api/postAddColumn',
    postEditColumn:'/api/postEditColumn',
    postDelColumn:'/api/postDelColumn',
    postUploadInventory:'/api/postUploadInventory',
    getShop:'/api/getShop',
    postShop:'/api/postShop',
    delShop:'/api/delShop',
    postAddCigarette:'/api/postAddCigarette',
    postEditCigarette:'/api/postEditCigarette',
    getPickup:'/api/getPickup',
    getPermissions_Review:'/api/getPermissions_Review',
    getPermissions:'/api/getPermissions',
    agreePermissions:'/api/agreePermissions',
    getAllPickup:'/api/getAllPickup',
    updataProductInfo:'/api/updataProductInfo',
    delUserInfo:'/api/delUserInfo',
    changeUserStaff:'/api/changeUserStaff',
    getShopList:'/api/getShopList',
    getFranchisee:'/api/getFranchisee',
    getResponsible:'/api/getResponsible',
    getBranchShop:'/api/getBranchShop',
    setBranchShop:'/api/setBranchShop',
    removeBranchShop:'/api/removeBranchShop',
    postAddUser:'/api/postAddUser'
}

export default base