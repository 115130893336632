<template>
  <div style="display: flex;flex-direction: column;align-items: center;">
  <div class="form">
    <el-form
        :model="ruleForm"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="padding-top: 50px;">
      <h2 style="padding-bottom: 30px;">联成后台管理登录</h2>
      <el-form-item label="用户名" prop="user">
        <el-input v-model.number="ruleForm.user" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">登录</el-button>
      </el-form-item>
    </el-form>
    </div>
  </div>
</template>
  <script>
  import { mapMutations } from 'vuex'
  import Qs from 'qs';
  export default {
    data () {
      return {
        ruleForm: {
          pass: "",
          user: ""
        },
      }
    },
    methods: {
      ...mapMutations('Admin',['getAdmin']),
      //登录按钮
      async submitForm() {
        const params = Qs.stringify({ username: this.ruleForm.user,password: this.ruleForm.pass })
        let res = await this.$api.getAdmin(params)
          if(res.status===200){
            this.$message({
              type: "success",
              message: '登录成功！'
            });
            // this.getAdmin({token:res.token});
            this.getAdmin(res.token);
            // window.sessionStorage.setItem("token",res.token)
          // sessionStorage.setItem('token',JSON.stringify(res.token))
          this.$router.push('/')
        }else{
          this.$message.error('登录失败，请检查用户名或密码!');
        }
      },
    }
  }
  </script>
  
  <style scoped lang="less">
  .form{ 
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 rgba(153, 187, 255, .33);
    margin-top: 20px;
    padding: 0 0 10px 40px;
    background-color: #ecf5ff;
    width: 500px;
}
  </style>