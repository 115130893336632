import Vue from "vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/css/base.css'
import 'normalize.css';
import "./plugins/element.js";
import api from './axios'
import axios from 'axios'
Vue.prototype.$api = api

Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.prototype.$http = axios;

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to,from,next) => {
  // var token = sessionStorage.getItem('token')
  if(to.path == '/login'){
    next();
  }else{
    // let token = sessionStorage.getItem('token');
    let token = window.sessionStorage.token
    if(token === null || token === ' ' || token === undefined){
      next('/login')
    }else{
      next();
    }
  }
});
//添加请求拦截器
axios.interceptors.request.use(
  config => {
    if(store.state.token){
      config.headers.common['token'] = store.state.token.token
    }
    return config;
  },
  error => {
    //请求错误
    return Promise.reject(error);
  }
);
//添加响应拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if(error.response){
      switch(error.response.status){
        case 401:
          localStorage.removeItem('token');
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath //登录成功后跳入浏览的当前页
            }
          })
      }
    }
  }
)


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
