<template>
    <div>
        <div style="text-align: center;width: 620px;">
            <h2>添加用户</h2>
        </div>
        <div>
            <el-form ref="form" :model="form" label-width="80px" :rules="rules" class="demo-ruleForm">
                <el-form-item label="账号" prop="username">
                    <el-input v-model="form.username" style="width: 500px;"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="form.password" show-password style="width: 500px;"></el-input>
                </el-form-item>
                <el-form-item label="分店" prop="shop">
                    <el-select v-model="form.shop" placeholder="请选择分店">
                        <el-option v-for="item in shop" :key="item.value" :label="item.label"
                            :value="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="职位" prop="pass">
                    <el-select v-model="form.pass" placeholder="请选择职位">
                        <el-option label="店员" value="1"></el-option>
                        <el-option label="分拣员" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即创建</el-button>
                    <el-button @click="goback">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import Qs from 'qs';
import pinyin from 'js-pinyin';
export default {
    data() {
        return {
            form: {
                username: '',
                password: '',
                shop: '',
                pass: '',
            },
            rules: {
                username: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                shop: [
                    { required: true, message: '请选择门店', trigger: 'change' }
                ],
                pass: [
                    { required: true, message: '请选择职位', trigger: 'change' }
                ],
            },
            shop: [],
            positions: ''
        }
    },
    methods: {
        goback() {
            this.$router.push('/StaffManagement')
        },
        onSubmit(form) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.adduser()
                } else {
                    this.$message.error('添加失败，请检查信息是否全部填写');
                    return false;
                }
            });
        },
        async adduser(){
            if (this.form.pass == 1) {
                this.positions = '店员'
            } else if (this.form.pass == 2) {
                this.positions = '分拣员'
            }
            pinyin.setOptions({ checkPolyphone: false, charCase: 1 });
            const shopid = pinyin.getFullChars(this.form.shop)
            const params = Qs.stringify({
                username: this.form.username,
                password: this.form.password,
                shop: this.form.shop,
                pass: this.form.pass,
                positions: this.positions,
                shopid: shopid
            })
            let res = await this.$api.postAddUser(params)
            if (res.status == 200) {
                this.$message({
                    message: '创建成功',
                    type: 'success'
                });
                this.$router.push('/StaffManagement')
            } else {
                this.$message.error('创建失败');
            }
        },
        async getshop() {
            let res = await this.$api.getShop()
            if (res.status == 200) {
                const arr = res.data
                this.shop = arr.map((i, n) => ({
                    label: i.ShopName,
                    value: i.shopindex
                }))
            }
        },
    },
    created() {
        this.getshop()
    }
}
</script>

<style></style>