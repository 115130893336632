import Vue from "vue";
import VueRouter from "vue-router";
import Layout from '@/views/layout/Index.vue';
import Home from '@/views/home/Index.vue';
import Product from '@/views/product/Index.vue';
import Atype from '@/views/product/information/Atype.vue';
import Btype from '@/views/product/information/Btype.vue';
import Ctype from '@/views/product/information/Ctype.vue';
import Cigarette from '@/views/product/information/Cigarette.vue';
import Addinformation from '@/views/product/information/Addinformation.vue';
import Productinfo from '@/views/product/information/Productinfo.vue';
import Inventory from '@/views/product/inventory/Index.vue';
import Login from '@/views/login/Loginhoutai.vue';
import AddCigaretteInfo from '@/views/product/information/AddCigaretteInfo.vue';
import Pickup from '@/views/Pickup/Index.vue';
import a from '@/views/Pickup/a.vue';
import b from '@/views/Pickup/b.vue';
import c from '@/views/Pickup/c.vue';
import cig from '@/views/Pickup/cigarette.vue';
import Staff from '@/views/Staff/Index.vue';
import StaffManagement from '@/views/Staff/management/Index.vue';
import AddUser from "@/views/Staff/management/AddUser.vue";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path:'/',
    component:Layout,
    children:[
      {
        path:'/',
        name:'home',
        component:Home,
        meta: {
          title: '联成'
        }
      },
      {
        path:'/product',
        name:'product',
        component:Product,
        children:[
          {
            path:'/Atype',
            name:'Atype',
            component:Atype,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/Btype',
            name:'Btype',
            component:Btype,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/Ctype',
            name:'Ctype',
            component:Ctype,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/Cigarette',
            name:'Cigarette',
            component:Cigarette,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/Addinformation',
            name:'Addinformation',
            component:Addinformation,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/Productinfo',
            name:'Productinfo',
            component:Productinfo,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/inventory',
            name:'inventory',
            component:Inventory,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/AddCigaretteInfo',
            name:'AddCigaretteInfo',
            component:AddCigaretteInfo,
            meta: {
              title: '联成'
            }
          }
        ]
      },
      {
        path:'/Pickup',
        name:'Pickup',
        component:Pickup,
        children:[
          {
            path:'/a',
            name:'a',
            component:a,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/b',
            name:'b',
            component:b,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/c',
            name:'c',
            component:c,
            meta: {
              title: '联成'
            }
          },
          {
            path:'/cig',
            name:'cig',
            component:cig,
            meta: {
              title: '联成'
            }
          }
        ]
      },
      {
        path:'/Staff',
        name:'Staff',
        component:Staff,
        children:[
          {
            path:'/StaffManagement',
            name:'StaffManagement',
            component:StaffManagement,
            meta: {
              title: '联成'
            }
          },{
            path:'/AddUser',
            name:'AddUser',
            component:AddUser,
            meta: {
              title: '联成'
            }
          },
        ]
      }
    ]
  },
  {
    path:'/login',
    name:'login',
    component:Login,
    meta: {
      title: '登录'
    }
  },
];



const router = new VueRouter({
  mode:'history',
  base:process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title

  }
  next()
})



export default router;
